import { DestroyRef, inject, Injectable } from '@angular/core';
import { CartItem } from '@app/cart/interfaces/cart-item.interface';
import { CartState } from '@app/cart/interfaces/cart-state.interface';
import { HomeworkApiService } from '@app/core/services/homework-api.service';
import { ServerInteractionStateType } from '@core/enums/server-interaction-state-type.enum';
import { shouldLoadData } from '@core/utils/server-interaction-state.utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';

import * as CartActions from './cart.actions';
import * as CartSelectors from './cart.selectors';

export enum CartEffectMessageType {
  SET_CART_ITEMS,
}

export interface CartEffectsBroadcastMessage {
  messageType: CartEffectMessageType;
  messageData: CartItem[];
}

@Injectable()
export class CartEffects {
  private destroyRef = inject(DestroyRef);
  private actions$ = inject(Actions);
  private homeworkApiService = inject(HomeworkApiService);
  private cartStore: Store<CartState> = inject(Store<CartState>);

  /**
   * A broadcast channel to communicate between cart effects in multiple tabs/windows.
   */
  private broadcastChannel = new BroadcastChannel('cart-effects');

  constructor() {
    this.broadcastChannel.onmessage = (message: MessageEvent<CartEffectsBroadcastMessage>) => {
      if (message.data.messageType === CartEffectMessageType.SET_CART_ITEMS) {
        this.cartStore.dispatch(CartActions.setCartItems({ items: message.data.messageData }));
      }
    };

    this.destroyRef.onDestroy(() => {
      this.broadcastChannel.close();
    });
  }

  broadcastAndSetCartItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.broadcastAndSetCartItems),
      // Post a message to other tab/windows with the new items
      tap(({ items }) =>
        this.broadcastChannel.postMessage({
          messageType: CartEffectMessageType.SET_CART_ITEMS,
          messageData: items,
        } as CartEffectsBroadcastMessage),
      ),
      // Relay the items to the ordinary setItems action
      map(({ items }) => CartActions.setCartItems({ items })),
    ),
  );

  fetchCart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.fetchCart),
      exhaustMap(() => {
        this.cartStore.dispatch(
          CartActions.setCartServerInteractionState({ state: ServerInteractionStateType.LOADING }),
        );

        return this.homeworkApiService.fetchCart().pipe(
          switchMap((cart) => {
            return [
              CartActions.broadcastAndSetCartItems({ items: cart.items }),
              CartActions.setCartServerInteractionState({ state: ServerInteractionStateType.SUCCESS }),
            ];
          }),
          catchError((error) =>
            of(CartActions.setCartServerInteractionState({ state: ServerInteractionStateType.ERROR, error })),
          ),
        );
      }),
    );
  });

  fetchCartIfNecessary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.fetchCartIfNecessary),
      concatLatestFrom(() => [this.cartStore.select(CartSelectors.selectCartServerInteractionState)]),
      exhaustMap(([action, serverInteractionState]) => {
        if (shouldLoadData(serverInteractionState.state)) {
          return [CartActions.fetchCart()];
        }

        return [];
      }),
    ),
  );

  emptyCart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.emptyCart),
      exhaustMap(() => {
        this.cartStore.dispatch(
          CartActions.setEmptyCartServerInteractionState({ state: ServerInteractionStateType.LOADING }),
        );

        return this.homeworkApiService.emptyCart().pipe(
          switchMap((cart) => {
            return [
              CartActions.broadcastAndSetCartItems({ items: [] }),
              CartActions.setEmptyCartServerInteractionState({ state: ServerInteractionStateType.SUCCESS }),
            ];
          }),
          catchError((error) =>
            of(CartActions.setEmptyCartServerInteractionState({ state: ServerInteractionStateType.ERROR, error })),
          ),
        );
      }),
    );
  });

  addItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.addItem),
      exhaustMap((action) => {
        this.cartStore.dispatch(
          CartActions.setAddToCartServerInteractionState({
            cartItemRequest: action.cartItemRequest,
            state: ServerInteractionStateType.LOADING,
          }),
        );

        return this.homeworkApiService.addItem(action.cartItemRequest).pipe(
          switchMap((cart) => {
            return [
              CartActions.broadcastAndSetCartItems({ items: cart.items }),
              CartActions.setAddToCartServerInteractionState({
                cartItemRequest: action.cartItemRequest,
                state: ServerInteractionStateType.SUCCESS,
              }),
            ];
          }),
          catchError((error) => [
            CartActions.setAddToCartServerInteractionState({
              cartItemRequest: action.cartItemRequest,
              state: ServerInteractionStateType.ERROR,
              error,
            }),
          ]),
        );
      }),
    );
  });

  removeItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.removeItem),
      exhaustMap((action) => {
        this.cartStore.dispatch(
          CartActions.setRemoveFromCartServerInteractionState({
            cartItemRequest: action.cartItemRequest,
            state: ServerInteractionStateType.LOADING,
          }),
        );

        return this.homeworkApiService.removeItem(action.itemId).pipe(
          switchMap((cart) => {
            return [
              CartActions.broadcastAndSetCartItems({ items: cart.items }),
              CartActions.setRemoveFromCartServerInteractionState({
                cartItemRequest: action.cartItemRequest,
                state: ServerInteractionStateType.SUCCESS,
              }),
            ];
          }),
          catchError((error) => [
            CartActions.setRemoveFromCartServerInteractionState({
              cartItemRequest: action.cartItemRequest,
              state: ServerInteractionStateType.ERROR,
              error,
            }),
          ]),
        );
      }),
    );
  });
}
