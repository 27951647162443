import { NgIf, NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { Tooltip } from '@shared-modules/tooltip/interfaces/tooltip.interface';
import { isAnIcon } from '@shared-utils/icon.utils';

@Component({
  imports: [NgIf, NgTemplateOutlet],
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent implements AfterViewInit, OnChanges {
  @Input() tooltip!: Tooltip;

  @HostBinding('role') readonly role = 'tooltip';
  @HostBinding('id') get elementId(): string {
    return this.tooltip.elementId;
  }
  @HostBinding('class') get directionClassName(): string {
    return [`direction-${this.tooltip.direction}`, this.tooltip.className].filter((a) => a).join(' ');
  }
  @HostBinding('class.in-modal') get inModal(): boolean {
    return this.tooltip.inModal;
  }
  @HostBinding('class.in-dropdown') get inDropdown(): boolean {
    return this.tooltip.inDropdown;
  }
  @HostBinding('style.--top') topStyle: string = '0px';
  @HostBinding('style.--left') leftStyle: string = '0px';
  @HostBinding('style.--arrow-offset') arrowOffsetStyle: string = '0px';

  public get tooltipHeading(): string | null {
    return typeof this.tooltip.heading === 'string' ? this.tooltip.heading : null;
  }

  public get tooltipInnerHTML(): string | null {
    return typeof this.tooltip.content === 'string' ? this.tooltip.content : null;
  }

  public get tooltipTemplate(): TemplateRef<any> | null | undefined {
    return typeof this.tooltip.content === 'string' || isAnIcon(this.tooltip.content) ? null : this.tooltip.content;
  }

  /**
   * The min distance between the edge of the window and the tooltip
   */
  private windowMargin = 20;

  /**
   * The distance between the edge of the target and the tooltip.
   * The tooltip arrow will be drawn within this space.
   */
  private targetMargin = 10;

  constructor(
    private el: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngAfterViewInit() {
    window.requestAnimationFrame(() => {
      this.updatePosition();
    });
  }

  ngOnChanges() {
    this.updatePosition();
  }

  private getYOffset(height: number, direction: 'up' | 'down'): number {
    let yOffset = height / 2 + this.targetMargin;

    if (direction === 'up') {
      return yOffset * -1;
    }

    return yOffset;
  }

  private updatePosition(): void {
    const targetBoundingRect = this.tooltip.targetBoundingRect;
    const tooltipBoundingRect = this.el.nativeElement.getBoundingClientRect() as DOMRect;

    // Get the middle of the element
    const x = targetBoundingRect.x + targetBoundingRect.width / 2;
    const y = targetBoundingRect.y + targetBoundingRect.height / 2;

    // Scroll compensation
    const xWithScroll = x + window.scrollX;
    const yWithScroll = y + window.scrollY;

    const halfTooltipWidth = tooltipBoundingRect.width / 2;

    // Prevent the tooltip from overflowing the viewport to the left
    let xOffset = Math.min(x - halfTooltipWidth - this.windowMargin, 0) * -1;

    // Prevent the tooltip from overflowing the viewport to the right
    if (xOffset === 0) {
      xOffset = Math.min(window.innerWidth - (x + halfTooltipWidth + this.windowMargin), 0);
    }

    // Set the position using CSS custom properties used in transforms.
    // Using top/left values will result in unwanted deformation of the tooltip when approaching the right edge of the viewport.
    this.topStyle = yWithScroll + this.getYOffset(targetBoundingRect.height, this.tooltip.direction) + 'px';
    this.leftStyle = xWithScroll + xOffset + 'px';
    this.arrowOffsetStyle = xOffset + 'px';

    this.changeDetectorRef.detectChanges();
  }
}
